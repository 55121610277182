import { RouteLocationNormalized } from 'vue-router';

function getCookieValue(cookieName: string) {
  // https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript
  const cookieValue = document.cookie.match('(^|[^;]+)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : '';
}

export function loginRequired(to: RouteLocationNormalized) {
  // public pages have the meta.public property set to true
  const isPublic = to.meta && to.meta.public;
  return !isPublic;
}

export function unauthorizedAccess(to: RouteLocationNormalized) {
  return loginRequired(to) && getCookieValue('loginStatus') !== 'true';
}

export function joiningClass(to: RouteLocationNormalized) {
  return to.name && (to.name === 'join-class' || to.name === 'licenseActivation');
}
