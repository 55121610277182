<template>
  <div
    class="read-only-banner"
    data-cy="read-only-banner"
    v-if="isReadOnly"
  >
    <div class="read-only-banner__content">
      <p class="read-only-banner__text">{{ readOnlyText }} Sie können Inhalte lesen, aber nicht bearbeiten.</p>
      <div class="read-only-banner__buttons">
        <router-link
          :to="licenseActivationLink"
          data-cy="license-activation-link"
          class="read-only-banner__link button button--primary"
          v-if="me.readOnly"
        >
          Neuen Lizenzcode eingeben
        </router-link>
        <a
          target="_blank"
          href="https://myskillbox.ch/lesemodus"
          class="button button--secondary"
          >Mehr Informationen zum Lesemodus</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { LICENSE_ACTIVATION } from '@/router/auth.names';

export default {
  data() {
    return {
      me: {
        readOnly: false,
        selectedClass: {
          readOnly: false,
        },
      },
      licenseActivationLink: {
        name: LICENSE_ACTIVATION,
      },
    };
  },

  apollo: {
    me: {
      query: gql`
        query ReadOnlyQuery {
          me {
            readOnly
            selectedClass {
              readOnly
            }
          }
        }
      `,
      fetchPolicy: 'cache-only',
      update({ me }) {
        if (!me) {
          return {
            readOnly: false,
            selectedClass: {
              readOnly: false,
            },
          };
        }
        return me;
      },
    },
  },

  computed: {
    readOnlyText() {
      return this.me.readOnly ? 'Sie besitzen keine aktive Lizenz.' : 'Sie sind in dieser Klasse nicht mehr aktiv.';
    },
    isReadOnly() {
      return this.me.readOnly || this.me.selectedClass?.readOnly;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'styles/helpers';

.read-only-banner {
  background-color: $color-brand-light;
  display: flex;
  justify-content: center;
  padding: $small-spacing 0;

  &__content {
    max-width: $screen-width;
  }

  &__text {
    padding: 0;
    @include regular-text;
    margin-bottom: $small-spacing;
  }

  &__buttons {
    display: flex;
    gap: $small-spacing;
  }

  &__link {
    @include default-link;
  }
}
</style>
