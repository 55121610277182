import ME_QUERY from '@/graphql/gql/queries/meQuery.gql';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { matomoTrackUser } from '@/helpers/matomo-client';

export interface Me {
  selectedClass: {
    id: string;
    readOnly: boolean;
  };
  permissions: string[];
  schoolClasses: any[];
  isTeacher: boolean;
  team: any;
  lastTopic: any;
  readOnly: boolean;
  lastModuleLevel: any;
}

export interface MeQuery {
  me: Me;
}

// todo: use the correct interface from vue router
export interface Location {
  name: string;
  params: {
    topicSlug: string;
  };
}

type RouteLocation = Location | string;

// TODO: ME_QUERY existiert an einem weiteren Ort. Dieser sollte entfernt werden.
const defaultMe: MeQuery = {
  me: {
    selectedClass: {
      id: '',
      readOnly: false,
    },
    permissions: [],
    schoolClasses: [],
    isTeacher: false,
    team: null,
    readOnly: false,
    lastTopic: undefined,
    lastModuleLevel: undefined,
  },
};

const getTopicRoute = (me: Me): RouteLocation => {
  if (me.lastTopic && me.lastTopic.slug) {
    return {
      name: 'topic',
      params: {
        topicSlug: me.lastTopic.slug,
      },
    };
  }
  return '/book/topic/berufliche-grundbildung';
};

const getSelectedClass = (me: Me) => {
  return me.selectedClass;
};

const getCanManageContent = (me: Me): boolean => {
  return me.isTeacher;
};

const getIsReadOnly = (me: Me): boolean => {
  return me.readOnly || me.selectedClass?.readOnly;
};

const getCurrentClassName = (me: Me) => {
  const currentClass = me.schoolClasses.find((schoolClass) => {
    return schoolClass.id === me.selectedClass.id;
  });
  return currentClass ? currentClass.name : me.schoolClasses.length ? me.schoolClasses[0].name : '';
};

const getMe = () => {
  const { result } = useQuery(ME_QUERY);

  const me = computed(() => result.value?.me || defaultMe.me);
  const topicRoute = computed(() => {
    return getTopicRoute(me.value);
  });
  const schoolClass = computed(() => {
    return me.value.selectedClass;
  });
  const canManageContent = computed(() => {
    return getCanManageContent(me.value);
  });
  const isReadOnly = computed(() => {
    return getIsReadOnly(me.value);
  });

  const currentClassName = computed(() => {
    return getCurrentClassName(me.value);
  });

  return { me, topicRoute, schoolClass, canManageContent, isReadOnly, currentClassName };
};

const meMixin = {
  data() {
    return defaultMe;
  },

  computed: {
    topicRoute(): RouteLocation {
      // @ts-ignore
      return getTopicRoute(this.$data.me);
    },
    schoolClass(): any {
      // @ts-ignore
      return getSelectedClass(this.$data.me);
    },
    canManageContent(): boolean {
      // @ts-ignore
      return getCanManageContent(this.$data.me);
    },
    isReadOnly(): boolean {
      // @ts-ignore
      return getIsReadOnly(this.$data.me);
    },
    currentClassName(): string {
      // @ts-ignore
      return getCurrentClassName(this.$data.me);
    },
  },

  apollo: {
    me: {
      query: ME_QUERY,
      // @ts-ignore
      update(data: any) {
        // todo: refactor
        // @ts-ignore
        return this.$getRidOfEdges(data).me;
      },
      // @ts-ignore
      result({ data }) {
        const me = data.me || defaultMe.me;
        if (me.id) {
          matomoTrackUser(me.id ?? '', me.isTeacher ? 'Teacher' : 'Student');
        }
      },
    },
  },
};

export { getMe, meMixin as default };
