export const CHOOSER = 'content-block-element-chooser-widget';
export const chooserFilter = (value) => value.type !== CHOOSER;
export const USER_CONTENT_TYPES = [
  'subtitle',
  'link_block',
  'video_block',
  'image_url_block',
  'text_block',
  'assignment',
  'document_block',
];

/*
  Users can only edit certain types of contents, the rest can only be re-ordered. We only care about their id, we won't
  send anything else to the server about them
 */
export const simplifyContents = (contents) => {
  return contents.map((c) => {
    if (USER_CONTENT_TYPES.includes(c.type)) {
      return c;
    }
    if (c.type === 'content_list_item') {
      return {
        ...c,
        contents: simplifyContents(c.contents),
      };
    }
    return {
      id: c.id,
      type: 'readonly',
    };
  });
};

export const cleanUpContents = (contents) => {
  let filteredContents = contents.filter(chooserFilter); // only use items that are not chooser elements

  return filteredContents.map((content) => {
    // if the element has a contents property, it's a list of contents, filter them
    if (content.contents) {
      return {
        ...content,
        contents: content.contents.filter(chooserFilter),
      };
    }
    // else just return it
    return content;
  });
};

// transform value prop to contents, to better handle the input type on the server
export const transformInnerContents = (contents) => {
  let ret = [];
  for (let content of contents) {
    if (Array.isArray(content.value)) {
      const { value, ...contentWithoutValue } = content;
      ret.push({
        ...contentWithoutValue,
        contents: value,
      });
    } else {
      ret.push(content);
    }
  }
  return ret;
};
