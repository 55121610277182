import apolloClientFactory from '@/graphql/client';

import { router } from '@/router';

function networkErrorCallback(statusCode: number) {
  if (statusCode === 402) {
    router.push({ name: 'licenseActivation' });
  }
}

const createApolloClients = () => {
  return {
    publicApolloClient: apolloClientFactory('/api/graphql-public/', null),
    privateApolloClient: apolloClientFactory('/api/graphql/', networkErrorCallback),
  };
};

const apolloClients = createApolloClients();

export default apolloClients;
